<template>
  <div>
    <location />
    <div class="page swing-view">
      <div class="wrap-1200">
        <h3 class="subtitle">{{ swingview.title }}</h3>
        <span class="text-date fs-14">
          <i class="material-icons me-1">date_range</i
          >{{ swingview.timezone_regdate.timezone_common | dateformat }}
        </span>

        <div class="ratio-16x9 mt-4">
          <video
            :src="swingview.path | get_img"
            class="w-100"
            muted
            controls
            autoplay
          ></video>
        </div>

        <div class="accordion" id="accordion-swing-info">
          <div class="accordion-item">
            <b-collapse id="collapseOne" v-model="visible">
              <div class="accordion-body">
                <div
                  class="row g-0 row-cols-2 row-cols-sm-4 row-cols-lg-6 table-type header-col p-keep"
                >
                  <div class="col">
                    {{ $t("title.common.club_type") }}
                  </div>
                  <div class="col">
                    {{ swingview.club || $t("text.common.unregistered") }}
                  </div>

                  <div class="col">
                    {{ $t("title.membership.ball_speed") }}
                  </div>
                  <div class="col">{{ swingview.ballspeed | comma }}km/h</div>

                  <div class="col">
                    {{ $t("title.membership.backspin") }}
                  </div>
                  <div class="col">{{ swingview.backspin | comma }}rpm</div>

                  <div class="col">
                    {{ $t("title.membership.carry") }}
                  </div>
                  <div class="col">{{ swingview.carry | comma }}m</div>

                  <div class="col">
                    {{ $t("title.membership.club_speed") }}
                  </div>
                  <div class="col">{{ swingview.clubspeed | comma }}km/h</div>

                  <div class="col">
                    {{ $t("title.membership.ball_direction") }}
                  </div>
                  <div class="col">{{ swingview.balldirection | comma }}°</div>

                  <div class="col">
                    {{ $t("title.membership.sidespin") }}
                  </div>
                  <div class="col">{{ swingview.sidespin | comma }}rpm</div>

                  <div class="col">
                    {{ $t("title.membership.distance") }}
                  </div>
                  <div class="col">{{ swingview.distance | comma }}m</div>

                  <div class="col">
                    {{ $t("title.membership.club_angle") }}
                  </div>
                  <div class="col">{{ swingview.clubnagel | comma }}°</div>

                  <div class="col border-bottom-lg-0">
                    {{ $t("title.membership.launch_angle") }}
                  </div>
                  <div class="col border-bottom-lg-0">
                    {{ swingview.launchangle | comma }}°
                  </div>

                  <div class="col border-bottom-sm-0 border-bottom-lg-0">
                    {{ $t("title.membership.ball_pitch") }}
                  </div>
                  <div class="col border-bottom-sm-0 border-bottom-lg-0">
                    {{ change_balltype(swingview.balltype) }}
                  </div>

                  <div class="col border-bottom-0">
                    {{ $t("title.membership.tee_height") }}
                  </div>
                  <div class="col">{{ swingview.teeheight | comma }}mm</div>
                </div>
                <!-- //row -->
              </div>
            </b-collapse>
            <div class="row g-0">
              <div class="col-lg-3 m-auto">
                <h2 class="accordion-header" id="headingOne">
                  <b-button
                    class="accordion-button"
                    :class="visible ? null : 'collapsed'"
                    :aria-expanded="visible ? 'true' : 'false'"
                    aria-controls="collapseOne"
                    variant="primary"
                    @click="visible = !visible"
                  >
                    {{ $t("button.common.swing_info_view") }}
                  </b-button>
                </h2>
              </div>
            </div>
          </div>
          <p class="content">
            {{ swingview.content }}
          </p>
          <div class="rebox">
            <b-form @submit="onSubmit">
              <div class="border-bottom comment">
                <p>
                  {{ $t("title.common.comment") }}
                  (<strong class="text-orange">{{
                    this.swingreplylistcnt
                  }}</strong
                  >)
                </p>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control fs-14 fs-lg-16"
                    :placeholder="$t('text.common.enter_comment_here')"
                    v-model="form.text"
                  />
                  <button
                    class="btn btn-primary btn-md position-relative"
                    type="submit"
                  >
                    <span
                      class="material-icons fs-14 position-absolute top-50 start-50 translate-middle"
                      >send</span
                    >
                  </button>
                </div>
              </div>
            </b-form>

            <div
              class="border-bottom reply"
              v-for="(item, index) in items"
              :key="index"
            >
              <div>
                <div class="d-flex flex-row">
                  <div>
                    <img
                      :src="item.member_photo_url | get_img"
                      class="center-cropped"
                    />
                  </div>

                  <div class="align-self-center">
                    <h6>
                      <MemberInfo
                        :nickname="item.nickname"
                        :playerno="item.playerno"
                      />
                    </h6>
                    <p class="text-date">
                      <i class="material-icons">schedule</i
                      >{{ item.timezone_date.timezone_common | dateformat }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div class="balloon text-break">
                  {{ item.text }}
                  <a
                    class="del position-absolute top-0 start-100 translate-middle"
                    v-if="item.register_fk === pk"
                    @click="del_reply(item.replyno)"
                  >
                    <i class="material-icons">cancel</i>
                  </a>
                </div>
              </div>
            </div>
            <!-- //reply -->
          </div>
          <!-- //rebox -->
        </div>

        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="page_total"
          v-model="pageno"
          use-router
          aria-label="Page navigation"
          v-if="page_total > 1"
          first-class="prev"
          prev-class="prev"
          next-class="next"
          last-class="next"
          hide-ellipsis
          limit="10"
        >
          <template #first-text>
            <i class="material-icons">keyboard_double_arrow_left</i>
          </template>
          <template #prev-text>
            <i class="material-icons">navigate_before</i>
          </template>
          <template #next-text>
            <i class="material-icons">navigate_next</i>
          </template>
          <template #last-text>
            <i class="material-icons">keyboard_double_arrow_right</i>
          </template>
          <template #page="{ page, active }">
            <a v-if="active">{{ page }}</a>
            <a v-else>{{ page }}</a>
          </template>
        </b-pagination-nav>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.list')"
            @click="
              $router.push({
                name: 'SwingList',
                query: {
                  searchtype: $route.query.searchtype,
                  searchname: $route.query.searchname,
                },
              })
            "
          >
            {{ $t("button.common.list") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/event";
import "@/filter/common";

export default {
  name: "netizenLessonView",
  components: {
    location,
  },
  data() {
    return {
      idx: this.$route.params.id,
      visible: false,
      swingview: [],
      pageno: parseInt(this.$route.query.page) || 1,
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      swingreplylistcnt: 0,
      form: {
        text: "",
      },
      token: sessionStorage.getItem("token") || false,
      pk: sessionStorage.getItem("pk") || 0,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
    };
  },
  methods: {
    get_swingview() {
      ax.get_swingview(this.idx, (flag, data) => {
        if (flag) {
          this.swingview = data.swingview;
        } else {
          alert(data);
        }
      });
    },
    change_balltype(type) {
      if (type == 1) return this.$i18n.t("text.common.straight");
      else if (type == 2) return this.$i18n.t("text.common.hook");
      else if (type == 3) return this.$i18n.t("text.common.slice");
      else if (type == 4) return this.$i18n.t("text.common.pull_straight");
      else if (type == 5) return this.$i18n.t("text.common.pull_hook");
      else if (type == 6) return this.$i18n.t("text.common.pull_slice");
      else if (type == 7) return this.$i18n.t("text.common.push_straight");
      else if (type == 8) return this.$i18n.t("text.common.push_hook");
      else if (type == 9) return this.$i18n.t("text.common.push_slice");
      else return "-";
    },
    get_swingreplylist() {
      ax.get_swingreplylist(this.idx, this.pageno, (flag, data) => {
        if (flag) {
          if (data.swingreplylistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.swingreplylistcnt;
          this.page_total = Math.ceil(data.swingreplylistcnt / this.rows);
          this.items = data.swingreplylist;
          this.swingreplylistcnt = data.swingreplylistcnt;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          this.items = [];
          this.swingreplylistcnt = 0;
        }
      });
    },
    del_reply(replayno) {
      ax.set_swingreplydrop(this.idx, replayno, (flag, data) => {
        if (flag) {
          if (this.pageno > 1) this.$router.push({ query: { page: 1 } });
          else this.get_swingreplylist();
        } else {
          alert(data);
        }
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    onSubmit(event) {
      event.preventDefault();
      // if (!this.token) {
      //   alert("로그인 후에 댓글 작성이 가능합니다.");
      //   return false;
      // }
      ax.set_swingreplywrite(this.idx, this.form.text, (flag, data) => {
        if (flag) {
          this.form.text = "";
          this.get_swingreplylist();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_swingview();
    this.get_swingreplylist();
  },
  watch: {
    "$route.query": function () {
      this.pageno = parseInt(this.$route.query.page) || 1;
      this.get_swingreplylist();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
