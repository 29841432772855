<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("text.event.main_title_1") }}</h3>
      <div class="row g-2 g-md-5">
        <div class="col-md-7 col-lg-8">
          <p>
            <span class="me-2" v-html="$t('text.event.main_sub_title_11')"></span>
            <TermDefinition getname="event" />
          </p>
          <p>
            {{ $t("text.event.main_sub_title_12") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_13") }}
          </p>          
        </div>
        <div class="col-md-5 col-lg-4">
          <img src="/img/minigame_shot1.jpg" class="img-fluid">
        </div>
      </div>
      <hr class="my-5">
      <h3 class="subtitle">{{ $t("text.event.main_title_2") }}</h3>
      <div class="row g-2 g-md-5">
        <div class="col-md-7 col-lg-8">
          <p>
            {{ $t("text.event.main_sub_title_21") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_22") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_23") }}
          </p>
          <p>
            {{ $t("text.event.main_sub_title_24") }}
          </p>                    
        </div>
        <div class="col-md-5 col-lg-4">
          <img src="/img/minigame_shot2.jpg" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import TermDefinition from "@/components/TermDefinition";
export default {
  name: "JoinMinigame",
  components: {
    location,
    TermDefinition,
  },
};
</script>